import React from 'react';
import { Typography, Flex } from 'antd';

const { Title } = Typography

interface EmptyIndexProps {
  title: string;
  subTitle: string;
  children: React.ReactNode | React.ReactNode[];
  Icon: React.FunctionComponent<React.SVGAttributes<SVGAElement>>
}

const QEmptyIndex: React.FC<EmptyIndexProps> = ({ title, subTitle, Icon, children }) => {
    
    return (
      <Flex vertical align='center' style={{ marginTop: '1.2em' }} gap='1.2em'>
        <Icon width="8em" height="8em" />
        <Flex vertical align='center' style={{ width: '50%' }} gap='1.2em'>
          <Title style={{ margin: 0, textAlign: 'center', wordBreak: 'keep-all' }} level={2}>{title}</Title>
          <Title style={{ margin: 0, fontWeight: 'normal', textAlign: 'center', wordBreak: 'keep-all' }} level={5}>{subTitle}</Title>
        </Flex>
        <Flex gap='0.5em' style={{ marginTop: '1em' }}>
          {children}
        </Flex>
      </Flex>
    )
}



export default QEmptyIndex;