import React from 'react';


const QLogo = ({ logoURL, logoHeight }) => (
    <div style={{height: 64, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <img
        srcSet={`/assets/logos/${logoURL}/small.png 480w,
              /assets/logos/${logoURL}/medium.png 800w,
              /assets/logos/${logoURL}/large.png 1200w`}
        sizes="(max-width: 480px) 480px,
              (max-width: 800px) 800px,
              1200px"
        src={`/assets/logos/${logoURL}/large.png`} alt="qyd_logo" style={{height: logoHeight}} />
    </div>
)

export default QLogo