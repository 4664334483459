import React from "react";
import RecurringTransactionsIcon from "assets/icons/Recurring-Transactions.svg";
import { Button, Col, Result, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const NoRecurringTransactions = () => {
  const { t } = useTranslation();

  return (
    <Row justify="center" align="middle">
      <Col lg={22} xl={16} xxl={11}>
        <Result
          icon={<RecurringTransactionsIcon fontSize={72} />}
          title={t("recurring_transactions.onboarding.title")}
          subTitle={t("recurring_transactions.onboarding.desc")}
          extra={
            <Button type="primary" href="recurring_transactions/new">
              <PlusOutlined /> {t("recurring_transactions.create")}
            </Button>
          }
        />
      </Col>
    </Row>
  );
};

export default NoRecurringTransactions;
