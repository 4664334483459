import React from 'react'
import {ConfigProvider} from 'antd'
import { QueryClient, QueryClientProvider } from 'react-query'
import { antdConfigProps } from 'pages/layout/App';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
        refetchOnWindowFocus: false,
        },
    },
})

const AntdWithTheme = ({ children }) => (
    <QueryClientProvider client={queryClient}>
        <ConfigProvider {...antdConfigProps}>
            {children}
        </ConfigProvider>
    </QueryClientProvider>
)

export default AntdWithTheme