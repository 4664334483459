import { Form, Checkbox, GetProp, message, Col, Row, Button, Skeleton, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import axios, { AxiosError } from 'axios';
import { getConfiguration } from 'api/dimensions';
import { Permissions } from 'types/app';


const options = ['ManualJournalEntry', 'EasyEntry', 'PurchaseOrder', 'Bill', 'SimpleBill', 'DebitNote', 'Quotation', 'Invoice', 'CreditNote', 'InventoryTransfer', 'StockTake', 'Production', 'Payroll', 'EmployeePayment', 'Loan', 'Bonus', 'Deduction']

const localesMap = new Map([
  ['ManualJournalEntry', 'activerecord.attributes.commercial_document.mje'],
  ['EasyEntry', 'activerecord.attributes.commercial_document.easy_entry'],
  ['PurchaseOrder', 'activerecord.attributes.commercial_document.purchase_order'],
  ['Bill', 'activerecord.attributes.commercial_document.bill'],
  ['SimpleBill', 'activerecord.attributes.commercial_document.simple_bill'],
  ['DebitNote', 'activerecord.attributes.commercial_document.debit_note'],
  ['Quotation', 'activerecord.attributes.commercial_document.quotation'],
  ['Invoice', 'activerecord.attributes.commercial_document.invoice'],
  ['CreditNote', 'activerecord.attributes.commercial_document.credit_note'],
  ['InventoryTransfer', 'activerecord.attributes.commercial_document.inventory_transfer'],
  ['StockTake', 'activerecord.attributes.commercial_document.stock_take'],
  ['Production', 'activerecord.attributes.commercial_document.production'],
  ['Payroll', 'activerecord.attributes.payroll.payroll'],
  ['EmployeePayment', 'activerecord.attributes.employee_payment.employee_payment'],
  ['Loan', 'activerecord.attributes.loan.loan'],
  ['Bonus', 'activerecord.attributes.bonus.bonus'],
  ['Deduction', 'activerecord.attributes.deduction.deduction']
])

interface ConfigurationOptionsProps {
  dimensionID: number;
  permissions: Permissions
}

const ConfigurationOptions = ({ dimensionID, permissions }: ConfigurationOptionsProps) => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [modal, modalContext] = Modal.useModal();
  const [disabled, setDisabled] = useState(false);
  const { data, isLoading } = useQuery<{model: string}[]>({
    queryKey: ['configurations', dimensionID],
    queryFn: getConfiguration.bind(null, { dimensionID })
  })


  const mutation = useMutation<any, AxiosError, {enabled_models: string[], dimensionID: number}>({
    mutationFn: ({dimensionID, enabled_models}) => {
      return axios.post(`/tenant/dimensions/${dimensionID}/configure.json`, { enabled_models })
    },
    onError: () => {
      messageApi.open({
        type: 'error',
        content: t('forms.messages.error')
      });
    },
    onSuccess: () => {
      messageApi.open({
        type: 'success',
        content: t('forms.messages.update.success')
      }); 
      setDisabled(true);
    }
  })


  const onChange: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues: string[]) => {
    const allIdx = checkedValues.indexOf(""); 
    if(allIdx > -1 && checkedValues.length < options.length) {
      return setCheckedList(["", ...options]);
    } else if(allIdx > -1 && checkedValues.length >= options.length) {
      checkedValues.splice(allIdx, 1);
    } else if(allIdx === -1 && checkedValues.length === options.length) {
      checkedValues.push("");
    }
    setCheckedList([...checkedValues])
    setDisabled(false);
  };
  
  useEffect(() => {   
    if(Boolean(data?.length)) {
      const readyData = data.map(val => val.model);
      if(readyData.length === options.length) {
        readyData.push("");
      }
      setCheckedList(readyData);
    }
  }, [ data ])

  const handleUpdateOptions = () => {
    const configs = [];
    for(const value of checkedList) {
      if(Boolean(value)) {
        configs.push(value)
      }
    }
    mutation.mutate({enabled_models:   configs, dimensionID })
  }

  const handleOk = () => {
    modal.confirm({
      content: t('activerecord.attributes.dimension.configuration.update'),
      okText: t('forms.buttons.confirm'),
      cancelText: t('forms.buttons.cancel'),
      onOk: () => {
        handleUpdateOptions();
      }
    });
  }

  
  if(isLoading) {
    return(
      <Skeleton active />
    )
  }
  
  return (
    <Checkbox.Group style={{ width: '100%' }} value={checkedList} onChange={onChange} disabled={!permissions?.write}>
      <Row gutter={[16, 16]}>
        <Col xs={12} md={8}>
          <Checkbox value="">
            {t('forms.placeholders.select_all')}
          </Checkbox>
        </Col>
        {options.map((entity, idx) => (
          <Col xs={12} md={8} key={`check${idx}`}>
            <Checkbox style={{fontWeight: 'normal'}} value={entity}>{t(localesMap.get(entity))}</Checkbox>
          </Col>
        ))}
        <Col span={24} style={{display: 'flex', justifyContent: 'end'}}>
          <Button type="primary" disabled={(mutation.isLoading || disabled || !permissions?.write)} onClick={handleOk}>
            {t("forms.buttons.save")}
          </Button>
        </Col>
      </Row>
      {contextHolder}
      {modalContext}
    </Checkbox.Group>
  )
}

export default ConfigurationOptions