import BackButton from "components/layout/BackButton";
import QPageLayout from "components/layout/QPageLayout";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { message, theme } from "antd";
import AccountBudgeting from "components/tenant/budgets/AccountBudgeting";
import ItemBudgeting from "components/tenant/budgets/ItemBudgeting";
import { useQuery } from "react-query";
import { getSingleBudget } from "api/budgets";
import dayjs, { Dayjs } from "dayjs";

export const useBreadCrumbData = () => {
  const { t, i18n } = useTranslation();
  return [
    {
      title: t("dashboard.quick_actions.accounting_header"),
    },
    {
      title: <a href="/tenant/budgets">{t("budgets.name")}</a>,
    },
    {
      title: t("budgets.select_budget"),
    },
  ];
};

const Edit = () => {
  const url = window.location.href;
  const parts = url.split("/");
  const id = parts[parts.length - 2];
  const { t, i18n } = useTranslation();
  const breadCrumbData = useBreadCrumbData();
  const [messageApi, contextHolder] = message.useMessage();
  const [budget, setBudget] = useState({});
  const [hasValues, setHasValues] = useState(false);
  const { data, isLoading } = useQuery({
    queryKey: ["budget_details", id],
    queryFn: () => getSingleBudget(id),
    onSuccess: (data) => {
      if (data.budgetings[0].budgeting_values) {
        setHasValues(true);
      }
      let resources = data.budgetings.map((el) => {
        return el.resource_id;
      });
      setBudget({
        code: data.code,
        description: data.description || "",
        name_ar: data.name_ar,
        name_en: data.name_en,
        fiscal_year: dayjs(data.fiscal_year),
        accounts_ids: resources,
        category_ids: data.kind == "products_budget" ? resources : [],
        asset_classification_ids: data.kind == "assets_budget" ? resources : [],
        base: data.base || null,
        kind: data.kind || null,
        transaction_type: data.transaction_type || null,
        dimensions: data.dimensions.map((el) => {
          return {
            selectedDimension: {
              label:
                i18n.language == "en"
                  ? `${el.code}- ${el.name_en}`
                  : `${el.code}- ${el.name_ar}`,
              value: el.id,
              name_ar: el.name_ar,
              name_en: el.name_en,
              code: el.code,
            },
          };
        }),
      });
    },
  });
  return (
    <QPageLayout
      title={t("budgets.edit.name")}
      actionBtns={<BackButton />}
      breadCrumbData={breadCrumbData}
    >
      {!isLoading && (
        <>
          {data?.kind == "accounts_budget" ? (
            <AccountBudgeting
              type={"edit"}
              budget={budget}
              id={id}
              hasValues={hasValues}
            />
          ) : (
            <ItemBudgeting
              type={"edit"}
              budget={budget}
              id={id}
              hasValues={hasValues}
            />
          )}
        </>
      )}
    </QPageLayout>
  );
};

export default Edit;
