import React from "react";
import { Row, Col, Select, Button } from "antd";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useResetAllModal } from "hooks/budgets";
const { Option } = Select;

const HeaderSection = ({ cycle, cycleCallBack, data, onReset }) => {
  const { t } = useTranslation();
  const nameKey = i18next.language === "en" ? "name_en" : "name_ar";
  const name = data ? data[nameKey] : undefined;
  const fiscalYear = data
    ? new Date(data?.fiscal_year).getFullYear()
    : undefined;
  const budgetKind = data ? t(`budgets.show.type.${data?.kind}`) : undefined;
  const { showResetModal, resetModal } = useResetAllModal(() => {
    onReset(null);
  });
  return (
    <>
      <Row
        style={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Col>
          <h2>{name}</h2>
          <div>
            <strong>
              {data?.base &&
                t(`budgets.show.budget_details.base.${data?.base}`)}
            </strong>
          </div>
          <div>
            <strong>{budgetKind}</strong>
          </div>
          <div>
            <p style={{ marginBottom: 0 }}>
              {t("budgets.attributes.reference")} {data?.code}
            </p>
          </div>
          <div>
            <p style={{ marginBottom: 0 }}>
              {t("budgets.attributes.fiscal_year")} {fiscalYear}
            </p>
          </div>
        </Col>
        <Col>
          <div>
            <strong style={{ margin: 20 }}>
              {t("budgets.show.header.view_period")}
            </strong>
            <Select
              defaultValue={cycle.type}
              onChange={(value) => {
                if (value === "quarterly") {
                  cycleCallBack({ type: "quarterly", no: 4 });
                } else {
                  cycleCallBack({ type: "monthly", no: 12 });
                }
              }}
              style={{ width: 250, marginTop: 10 }}
            >
              <Option value="monthly">{t("budgets.show.header.months")}</Option>
              <Option value="quarterly">
                {t("budgets.show.header.quarters")}
              </Option>
            </Select>
          </div>
          <div
            style={{
              display: "flex",
              marginTop: 15,
              justifyContent: "flex-end",
            }}
          >
            <Button type="primary" onClick={showResetModal}>
              {t("budgets.show.reset")}
            </Button>
          </div>
        </Col>
      </Row>
      {resetModal}
    </>
  );
};

export default HeaderSection;
