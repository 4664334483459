import React from "react";
import Link from "antd/es/typography/Link";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import QPageLayout from "components/layout/QPageLayout";
import NoRecurringTransactions from "components/tenant/recurring_transactions/NoRecurringTransactions";

const RecurringTransactions = () => {
  const { t } = useTranslation();
  const pageTitle = t("recurring_transactions.title");

  return (
    <QPageLayout
      title={pageTitle}
      breadCrumbData={[
        { title: <Link href="#">{t("activerecord.attributes.layout.accounting")}</Link> },
        { title: pageTitle },
      ]}
      actionBtns={
        <Button type="primary" href="recurring_transactions/new">
          <PlusOutlined /> {t("recurring_transactions.create")}
        </Button>
      }
    >
      <NoRecurringTransactions />
    </QPageLayout>
  );
};

export default RecurringTransactions;
