import React, { useEffect, useState } from 'react';
import { Form, Input, Flex, Button, message, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import QPageLayout from 'components/layout/QPageLayout';
import BackButton from 'components/layout/BackButton';
import axios from 'axios';
import { useMutation, useQuery } from 'react-query';
import { getDimension } from 'api/dimensions';
import { DimensionType, DimensionValue } from 'types/dimensions';

export const useBreadCrumbData = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('activerecord.attributes.layout.settings'),
    },
    {
      title: <a href="/tenant/dimensions">{t('activerecord.attributes.dimension.name')}</a>,
    },
    {
      title: t('activerecord.attributes.dimension_value.edit'),
    },
  ];
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    lg: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    lg: { span: 8 },
  },
};

interface DimensionValueData extends DimensionValue {
  dimension: DimensionType;
  parentValue: DimensionValue;
}

const EditDimensionValue = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const dimensionID = location.pathname.match(/\d+/)[0];
  const valueID = location.pathname.match(/\/values\/(\d+)/)[1];
  const [parentID, setParentID] = useState<'' | number>('');
  const breadCrumbData = useBreadCrumbData();
  const [initialData, setInitialData] = useState<DimensionValueData | null>(null);

  const dimensionValueQuery = useQuery<DimensionValue>({
    queryKey: ["parentID", parentID],
    queryFn: async () => {
      const { data } = await axios.get(`/tenant/dimensions/${dimensionID}/values/${valueID}.json`);
      return data;
    }
  });

  const dimensionQuery = useQuery<DimensionType>({
    queryKey: ["dimension"],
    queryFn: getDimension.bind(null, { dimensionID })
  })
  
  const dimensionParentValuesQuery = useQuery<DimensionValue>({
    queryKey: ["parentID", parentID],
    queryFn: async () => {
      const { data } = await axios.get(`/tenant/dimensions/${dimensionID}/values/${parentID}.json`);
      return data;
    },
    enabled: Boolean(parentID)
  });

  useEffect(() => {
    if(dimensionValueQuery.isSuccess) {
      const { parent_id } = dimensionValueQuery.data;
      setParentID(parent_id);
    }
  }, [ dimensionValueQuery.data ])


  useEffect(() => {
    if(dimensionParentValuesQuery.isSuccess, dimensionQuery.isSuccess, dimensionValueQuery.isSuccess) {
      setInitialData({
        ...dimensionValueQuery.data,
        parentValue: {...dimensionParentValuesQuery.data},
        dimension: {...dimensionQuery.data}
      })
    }
  }, [ dimensionParentValuesQuery.isSuccess, dimensionQuery.isSuccess, dimensionValueQuery.isSuccess ])

  useEffect(() => {
    if(initialData) {
      form.setFieldsValue({
        dimension_name: i18next.language === 'en' ? initialData.dimension.name_en : initialData.dimension.name_ar,
        dimension_code: initialData.dimension.code,
        code: initialData.code,
        value_type: initialData.value_type,
        parent_id: initialData.parentValue.id,
        name_en: initialData.name_en,
        name_ar: initialData.name_ar
      })
    }
  }, [ initialData ])

  const mutation = useMutation({
    mutationFn: (values: any) => {
      return axios.patch(`/tenant/dimensions/${dimensionID}/values/${valueID}.json`, values);
    },
    onSuccess: () => {
      window.location.href = `/tenant/dimensions`;
    },
    onError: () => {
      messageApi.open({
        type: 'error',
        content: t('forms.messages.error'),
      });
    },
  });

  return (
    <QPageLayout
      messageHolder={contextHolder}
      title={`${t('tooltip.edit')} ${initialData?.code} - ${i18next.language === 'en' ? initialData?.name_en : initialData?.name_ar}`}
      actionBtns={<BackButton />}
      breadCrumbData={breadCrumbData}
    >
      <Form
        labelWrap
        form={form}
        name="code"
        onFinish={() => {
          const values = form.getFieldsValue();
          mutation.mutate(values);
        }}
        scrollToFirstError
        validateMessages={{
          required: t("activerecord.attributes.dimension.create_code.errors.required") + " ${label}"
        }}
        {...formItemLayout}
      >
        <Form.Item
          name="dimension_name"
          label={t("activerecord.attributes.dimension_value.dimension_code_name")}
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="dimension_code"
          label={t("activerecord.attributes.dimension_value.dimension_code")}
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
            name="value_type"
            label={t("activerecord.attributes.dimension_value.value_type")}
        >
          <Select
            disabled
            options={[{ value: "parent_value", label: t("activerecord.attributes.dimension_value.value_types.parent_value") }, { value: "standard", label: t("activerecord.attributes.dimension_value.value_types.standard") }]}
          />
        </Form.Item>
        <Form.Item
          name="parent_id"
          label={t("activerecord.attributes.dimension_value.parent_dimension_value")}
        >
          <Select
            disabled
            options={[{ label: `${initialData?.parentValue.code} - ${i18next.language === 'en'? initialData?.parentValue.name_en : initialData?.parentValue.name_ar}`, value: initialData?.parentValue.id }]}
          />
        </Form.Item>
        <Form.Item
          name="code"
          label={t("activerecord.attributes.dimension_value.id")}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="name_ar"
          rules={[
            {
              required: true
            }
          ]}
          label={t("activerecord.attributes.dimension.create_code.name_ar")}
        >
          <Input maxLength={25} />
        </Form.Item>
        <Form.Item
          name="name_en"
          rules={[
            {
              required: true
            }
          ]}
          label={t("activerecord.attributes.dimension.create_code.name_en")}
        >
          <Input maxLength={25} />
        </Form.Item>
        <Form.Item wrapperCol={{ xs: { offset: 0 }, lg: { offset: 12 } }}>
          <Flex gap="small" justify='end'>
            <Button type="primary" disabled={mutation.isLoading || mutation.isSuccess ? true : false} htmlType="submit">
              {t("forms.buttons.save")}
            </Button>
            <Button danger href={`/tenant/dimensions.json`}>
              {t("forms.buttons.cancel")}
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </QPageLayout>
  );
};

export default EditDimensionValue;
